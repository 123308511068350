<template>
  <div>
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <BaseModal class="h-full" v-model="showCropper">
      <template v-slot:title>
        <slot name="modal-title"></slot>
      </template>
      <div
        class="mx-auto h-full flex flex-col justify-center content-center"
        v-if="imgSrc != '' && showCropper"
      >
        <template v-if="uploading">
          <div class="flex justify-center items-center w-full h-full">
            <div class="loading">
              <Spinner class="bg-default" />
            </div>
          </div>
        </template>
        <template v-else>
          <section
            class="cropper-area flex flex-grow justify-center flex-col text-center w-full"
            :class="showPreview ? 'hidden' : 'block'"
          >
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :initialAspectRatio="cropperAspectRatio"
                :src="imgSrc"
                alt="Source Image"
                :img-style="{ width: '100%' }"
                :view-mode="1"
                :crop-box-resizable="false"
                :cropBoxMovable="false"
                :minCropBoxWidth="100"
                :minCropBoxHeight="100"
                :guides="false"
                drag-mode="move"
                :auto-crop-area="cropperSize"
                :min-container-width="300"
                :min-container-height="300"
                :background="false"
                :rotatable="true"
              />
            </div>
            <!-- <button
              @click="cropAndShowPreview"
              class="w-full mx-auto text-center mt-4 text-blue capitalize"
            >
              Show preview
            </button> -->
          </section>
          <section
            :class="showPreview ? 'block' : 'hidden'"
            class="preview-area flex flex-col items-center justify-center justify-items-center"
          >
            <p class="capitalize text-indigo">Preview</p>
            <!-- <div class="preview mx-auto" /> -->
            <div class="cropped-image">
              <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
              <div v-else class="crop-placeholder" />
            </div>
            <button
              @click="showPreview = false"
              class="mx-auto mt-4 text-blue capitalize"
            >
              {{ $t("message.continue-editing") }}
            </button>
          </section>
          <div class="container mt-auto">
            <div class="actions">
              <button
                @click.prevent="uploadImageToS3()"
                class="mx-auto btn btn-lg btn-default my-4"
              >
                <span class="mx-auto">
                  {{ $t("message.apply") }}
                </span>
              </button>
            </div>
          </div>
        </template>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import Spinner from "@/components/shared/Spinner.vue";
import "cropperjs/dist/cropper.css";
import { GET_URL_FOR_IMAGE } from "@/graphql/imagesQueries.js";
import axios from "axios";

export default {
  components: {
    VueCropper,
    Spinner
  },
  props: {
    value: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    },
    cropperAspectRatio: {
      type: Number,
      required: false,
      default: 1
    },
    cropperSize: {
      type: Number,
      required: false,
      default: 1
    },
    buttonId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imgSrc: "",
      // imgSrc: "/img/profile.png",
      cropImg: "",
      fileExtension: "",
      data: null,
      uploading: false,
      newImageUrl: this.value,
      showCropper: false,
      showPreview: false,
      i: 0
    };
  },
  mounted() {
    var el = document.getElementById(this.buttonId);
    el.addEventListener("click", () => {
      this.showFileChooser();
    });
  },
  watch: {
    value(val) {
      this.newImageUrl = val;
      this.showPreview = false;
      this.uploading = false;
    }
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    reset() {
      this.$refs.cropper.reset();
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      this.showPreview = false;
      const file = e.target.files[0];
      this.fileExtension = file.name.split(".").pop();

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          } else {
            this.$refs.cropper = event.target.result;
          }
        };

        reader.readAsDataURL(file);
        this.showCropper = true;
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.$refs.input.value = "";
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    cropAndShowPreview() {
      this.cropImage();
      this.showPreview = true;
    },
    async uploadImageToS3() {
      this.cropImage();
      let today = new Date();
      const file = this.dataURLtoFile(
        this.cropImg,
        "profile-image-" + today + "." + this.fileExtension
      );
      this.uploading = true;
      this.newImageUrl = null;
      this.i = this.i + 1;

      const contentType = file.type;
      const extension = "." + file.name.split(".").pop();
      const urlToUpload = await this.$apollo
        .query({
          query: GET_URL_FOR_IMAGE,
          variables: {
            contentType: contentType,
            extension: extension
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          return res?.data?.getUploadUrl;
        })
        .catch(() => {
          console.log("error on apollo call getting signed url");
          return null;
        });
      if (urlToUpload) {
        // console.log(file);
        await axios.put(urlToUpload, file, {
          headers: {
            "Content-Type": contentType
          }
        });
        const resultUrl = urlToUpload.split("?");
        this.newImageUrl = resultUrl[0];
        this.uploading = false;
        // console.log(resultUrl[0]);
        this.i = this.i + 1;
        this.imgSrc = "";
        this.showCropper = false;
      } else {
        console.log("image not valid?");
      }
      // console.log("input", this.newImageUrl);
      this.$emit("input", this.newImageUrl);
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped>
.cropper-area {
  max-width: 400px;
}
input[type="file"] {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #c00;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 128px !important;
  height: 128px !important;
  overflow: hidden;
  border-radius: 100%;

  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image {
  width: 128px;
  height: 128px;
  overflow: hidden;
  border-radius: 100%;
}

.cropped-image img {
  width: 100%;
}
</style>
<style>
.cropper-point {
  background-color: #99e192;
  height: 10px;
  opacity: 0.75;
  width: 10px;
}

.cropper-container {
  margin: 0 auto;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 4px solid #99e192;
  outline-color: rgb(153 225 146);
  overflow: hidden;
  width: 100%;
}
</style>
