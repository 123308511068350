<template>
  <SettingsContainer :header="$t('message.edit-profile')" class="mb-6">
    <div class="w-full flex flex-col max-w-desktop mx-auto">
      <template v-if="profileEditing">
        <ProfileImageUploader
          :cropperAspectRatio="108 / 53"
          :cropperSize="0.9"
          buttonId="profile-background-button"
          v-model="profileEditing.backgroundImageUrl"
        >
          <template v-slot:modal-title>
            <h5>Crop Background Picture</h5>
          </template>
        </ProfileImageUploader>
        <ProfileImageUploader
          :cropperAspectRatio="1"
          :cropperSize="0.8"
          v-model="profileEditing.profileImageUrl"
          buttonId="profile-image-button"
        >
          <template v-slot:modal-title>
            <h5>{{ $t("message.crop-picture-profile") }}</h5>
          </template>
        </ProfileImageUploader>
        <div
          class="w-full relative overflow-hidden bg-gray-400 bg-cover bg-center flex justify-center items-center"
        >
          <img
            :src="profileBgImage"
            alt="user background image"
            class="w-full"
          />
          <div
            class="centered-elements"
            :class="{ 'grid grid-cols-2': profileEditing.backgroundImageUrl }"
          >
            <div class="centered-element">
              <img
                id="profile-background-button"
                src="@/assets/img/icons/camera.svg"
              />
            </div>
            <div
              v-if="profileEditing.backgroundImageUrl"
              class="centered-element"
              @click="profileEditing.backgroundImageUrl = null"
            >
              <img
                id="profile-background-delete-button"
                src="@/assets/img/icons/exit-icon-white.svg"
              />
            </div>
          </div>
        </div>
        <div class="md:w-1/2 md:mx-auto">
          <div class="cropped-image-box flex justify-center flex-col">
            <div class="cropped-image  md:border-8 border-white relative">
              <div class="centered-elements">
                <div class="centered-element">
                  <img
                    id="profile-image-button"
                    src="@/assets/img/icons/camera.svg"
                  />
                </div>
              </div>
              <img
                v-if="profileEditing.profileImageUrl"
                :src="profileEditing.profileImageUrl"
                alt="Cropped Image"
              />
              <Avatar
                v-else
                class="profile-img crop-placeholder bg-white border-8 border-white rounded-full object-cover text-4xl md:text-7xl"
                avatarType="large"
                text="large"
                :clickable="false"
                :displayName="profileEditing.screenName"
                :profileImageUrl="null"
              />
            </div>
            <div
              v-if="profileEditing.profileImageUrl"
              class="flex flex-row md:absolute md:-right-16 -right-0 bottom-0"
            >
              <button
                class="bg-blue-900 w-7 h-7 rounded-full flex justify-center items-center mr-2"
                @click="profileEditing.profileImageUrl = null"
              >
                <img
                  class="w-3 h-3"
                  src="@/assets/img/icons/exit-icon-white.svg"
                />
              </button>
              <button
                class="text-blue-900 font-tiny"
                @click="profileEditing.profileImageUrl = null"
              >
                {{ $t("message.remove-profile-image") }}
              </button>
            </div>
          </div>
          <div class="w-full mt-4">
            <form @submit.prevent="updateProfile()">
              <InputWrapper
                class="mt-4"
                name="displayName"
                :label="$t('message.form-fields.edit-profile.displayName')"
                type="text"
                :error="hasError('displayName')"
                :touched="touched('displayName')"
                :hint="hint('displayName')"
                v-model="profileEditing.displayName"
                @input="validateForm()"
                @blur="changed('displayName')"
              />
              <InputWrapper
                class="mt-4"
                name="screenName"
                :label="$t('message.form-fields.edit-profile.screenName')"
                type="text"
                :error="hasError('screenName')"
                :touched="touched('screenName')"
                :hint="hint('screenName')"
                v-model="profileEditing.screenName"
                @input="validateForm()"
                @blur="changed('screenName')"
              />
              <InputWrapper
                class="mt-4"
                name="bio"
                :label="$t('message.form-fields.edit-profile.bio')"
                type="textarea"
                :error="hasError('bio')"
                :touched="touched('bio')"
                :hint="hint('bio')"
                v-model="profileEditing.bio"
                @input="validateForm()"
                @blur="changed('bio')"
              />
              <div v-if="error" class="text-red text-center w-full my-4">
                {{ error }}
              </div>
              <button type="submit" class="btn btn-lg btn-default mt-6">
                <span class="mx-auto" v-if="!loading">
                  {{ $t("message.save") }}
                </span>
                <div class="loading w-6 h-6" v-else>
                  <Spinner class="sm-button" />
                </div>
              </button>
            </form>
          </div>
        </div>
      </template>
    </div>
  </SettingsContainer>
</template>

<script>
import SettingsContainer from "@/components/settings/SettingsContainer.vue";
import ProfileImageUploader from "@/components/shared/ProfileImageUploader.vue";
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import Avatar from "@/components/shared/Avatar.vue";
import { GET_EDIT_PROFILE_FIELDS } from "@/graphql/queries/user/userQueries.js";
import {
  PROFILE_BASIC_MUTATION,
  MODERATE_PROFILE_MUTATION
} from "@/graphql/queries/user/userMutations.js";
import { mapGetters } from "vuex";

export default {
  name: "ProfileEditPage",
  components: {
    Avatar,
    SettingsContainer,
    ProfileImageUploader,
    InputWrapper,
    Spinner
  },
  data() {
    return {
      profileEditing: null,
      profileImage: "",
      backgroundImage: "",
      validation: {},
      showAllErrors: false,
      name: "",
      loading: false,
      skipGetProfile: false,
      error: null
    };
  },
  apollo: {
    profile: {
      query: GET_EDIT_PROFILE_FIELDS,
      update(data) {
        //can modify the response here
        this.skipGetProfile = true;
        this.profileEditing = { ...data.getProfile };
        return data.getProfile;
      },
      variables() {
        return {
          screenName: this.$store.getters["auth/currentUser"]?.attributes
            .screenName,
          profileId: this.$route.params?.profileId || null
        };
      },
      //test if this property is needed
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      skip() {
        return this.skipGetProfile;
      }
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    ...mapGetters("auth", ["currentUser"]),
    profileBgImage() {
      return (
        this.profileEditing.backgroundImageUrl ||
        require("@/assets/img/hardcoded/default-user-background.svg")
      );
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    },
    isModeratorView() {
      if (this.profileEditing.id == this.currentUser.id) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      const screenName = this.profileEditing.screenName.trimStart().trimEnd();
      const displayName = this.profileEditing.displayName.trimStart().trimEnd();
      const bio = this.profileEditing.bio.trimStart().trimEnd();
      let valid = true;
      if (screenName == "" || screenName.length < 6) {
        this.addError(
          "screenName",
          this.$t("message.formValidation.too_short", { val: 6 })
        );
        valid = false;
      } else if (screenName.length > 48) {
        this.addError(
          "screenName",
          this.$t("message.formValidation.too_long", { val: 48 })
        );
        valid = false;
      } else {
        this.clearError("screenName");
      }
      if (displayName == "") {
        this.addError("displayName", this.$t("message.formValidation.empty"));
        valid = false;
      } else if (displayName.length < 2) {
        this.addError(
          "displayName",
          this.$t("message.formValidation.too_short", { val: 2 })
        );
        valid = false;
      } else if (displayName.length > 255) {
        this.addError(
          "displayName",
          this.$t("message.formValidation.too_long", { val: 255 })
        );
        valid = false;
      } else {
        this.clearError("displayName");
      }
      if (bio.length > 5000) {
        this.addError(
          "bio",
          this.$t("message.formValidation.too_long", { val: 5000 })
        );
        valid = false;
      } else {
        this.clearError("bio");
      }
      return valid;
    },
    updateProfile() {
      this.error = null;
      this.showAllErrors = true;
      this.resetForm();
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      const screenName = this.profileEditing.screenName.trimStart().trimEnd();
      const displayName = this.profileEditing.displayName.trimStart().trimEnd();
      const bio = this.profileEditing.bio.trimStart().trimEnd();
      this.$apollo
        .mutate({
          mutation: this.isModeratorView
            ? MODERATE_PROFILE_MUTATION
            : PROFILE_BASIC_MUTATION,
          variables: {
            profileId: this.profileEditing.id,
            newProfile: {
              displayName: displayName,
              oldScreenName: this.profile.screenName,
              screenName: screenName,
              bio: bio,
              profileImageUrl: this.profileEditing.profileImageUrl,
              backgroundImageUrl: this.profileEditing.backgroundImageUrl
            }
          }
        })
        .then(() => {
          if (this.isModeratorView) {
            this.$router.push({
              name: "ProfilePage",
              params: {
                screenName
              }
            });
          } else {
            this.$store.dispatch("auth/refreshAuthenticatedUser").then(() => {
              this.$router.push({
                name: "MyProfilePage"
              });
            });
          }
        })
        .catch(error => {
          this.handleUpdateProfileErrors(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdateProfileErrors(error) {
      if (!error.graphQLErrors || error.graphQLErrors.length == 0) {
        console.error(error);
        this.error = this.$t("message.graphql-request-failed");
        return;
      }
      this.error = this.$t(
        `message.appsync-errors.${error.graphQLErrors[0].errorType}`
      );
      error.graphQLErrors.forEach(err => {
        if (err.errorInfo) {
          err.errorInfo.forEach(field => {
            const value = field.errors[0].min || field.errors[0].max || null;
            let message = this.$t(
              `message.formValidation.${field.errors[0].type}`,
              { val: value }
            );
            this.validation[field.fieldName] = {
              error: true,
              hint: message
            };
          });
        }
      });
      this.showAllErrors = true;
    }
  }
};
</script>
<style lang="postcss" scoped>
.crop-placeholder {
  width: 100%;
  height: 100%;
}

.cropped-image-box {
  position: relative;
}

.cropped-image {
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media screen and (min-width: 768px) {
    margin-top: -78px;
    margin: -78px auto 0 auto;
    width: 156px;
    height: 156px;
  }
}

.cropped-image img {
  width: 100%;
}

.centered-elements {
  position: absolute;
  gap: 10px;
}
.centered-element {
  height: 38px;
  width: 38px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 8px;
  background-color: #00000085;
}
</style>
